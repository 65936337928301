import { useMemo } from "react";
import { AvailablesAvatar, CustomAvatarModel } from "../model/Avatar";
import "./WaitingScreen.css";

export interface WaitingScreenProps {
  hasVipButton: boolean;
  avatarName: string;
  onGameStart: () => void;
  onWaitingButtonPress: (avatarName: string) => void;
}

export function WaitingScreen(props: WaitingScreenProps) {
  const avatars = useMemo<CustomAvatarModel[]>(
    () => [
      {
        name: AvailablesAvatar.MOUTON,
        className: "mouton",
      },
      {
        name: AvailablesAvatar.SINGE,
        className: "singe",
      },
      {
        name: AvailablesAvatar.SOURIS,
        className: "souris",
      },
      {
        name: AvailablesAvatar.COCHON,
        className: "cochon",
      },
      {
        name: AvailablesAvatar.OISEAU,
        className: "piaf",
      },
      {
        name: AvailablesAvatar.CHEVRE,
        className: "chevre",
      },
      {
        name: AvailablesAvatar.TRICERATOPS,
        className: "trice",
      },
      {
        name: AvailablesAvatar.CHAT,
        className: "chat",
      },
    ],
    []
  );

  const avatarClassName = useMemo(() => {
    return avatars.find((item) => item.name === props.avatarName)?.className;
  }, [avatars, props.avatarName]);

  return (
    <div className="WaitingScreenContainer">
      <h1 className="Title">En attente des autres joueurs ... </h1>
      <div className="WaitingImage"></div>
      {props.hasVipButton && (
        <button className="StartGame" onClick={props.onGameStart}>
          Start game
        </button>
      )}
      <div className="buttonbottom">
        <div className="buttonContainer">
          <button className={"playerselect " + avatarClassName} onClick={() => props.onWaitingButtonPress(props.avatarName)}></button>
        </div>
      </div>
    </div>
  );
}
