import { useCallback, useState } from "react";
import "./RegisterComponent.css";

export interface JoinRoomComponentProps {
  onJoin: (roomId: string, playerName: string) => void;
}

export function JoinRoomComponent(props: JoinRoomComponentProps) {
  const [roomId, setRoomId] = useState("");
  const [playerName, setPlayerName] = useState("");

  const validateCallback = useCallback(() => {
    props.onJoin(roomId, playerName);
  }, [playerName, props, roomId]);

  return (
    <div className="JoinRoomContainer">
      <input
        className="InputRegister"
        value={roomId}
        onChange={(e) => setRoomId(e.target.value)}
        placeholder="Identifiant de la partie"
      />
      <input
        className="InputRegister MarginTop"
        value={playerName}
        onChange={(e) => setPlayerName(e.target.value)}
        placeholder="Pseudo"
      />
      <button className="InputRegister MarginTop join" onClick={validateCallback}>
        JOIN
      </button>
    </div>
  );
}
