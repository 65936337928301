export interface AvatarModel {
  name: AvailablesAvatar;
  img: string;
  activeImg: string;
}

export interface CustomAvatarModel {
  name: AvailablesAvatar,
  className: string,
}

export enum AvailablesAvatar {
  MOUTON = "MOUTON",
  SINGE = "SINGE",
  SOURIS = "SOURIS",
  COCHON = "COCHON",
  OISEAU = "OISEAU",
  CHEVRE = "CHEVRE",
  TRICERATOPS = "TRICERATOPS",
  CHAT = "CHAT",
}
