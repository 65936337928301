import { useCallback, useMemo, useState } from "react";
import { Avatar } from "../AppState";
import { AvailablesAvatar, AvatarModel } from "../model/Avatar";
import "./SelectAvatar.css";

export interface SelectAvatarProps {
  onAvatarSelected: (avatar: Avatar) => void;
}

export function SelectAvatar(props: SelectAvatarProps) {
  const [selectedAvatar, setSelectedAvatar] = useState<AvatarModel | undefined>(
    undefined
  );

  const avatars = useMemo<AvatarModel[]>(
    () => [
      {
        name: AvailablesAvatar.MOUTON,
        img: "images/mouton.png",
        activeImg: "images/moutonValidated.png",
      },
      {
        name: AvailablesAvatar.SINGE,
        img: "images/singe.png",
        activeImg: "images/singeValidated.png",
      },
      {
        name: AvailablesAvatar.SOURIS,
        img: "images/souris.png",
        activeImg: "images/sourisValidated.png",
      },
      {
        name: AvailablesAvatar.COCHON,
        img: "images/cochon.png",
        activeImg: "images/cochonValidated.png",
      },
      {
        name: AvailablesAvatar.OISEAU,
        img: "images/piaf.png",
        activeImg: "images/piafValidated.png",
      },
      {
        name: AvailablesAvatar.CHEVRE,
        img: "images/chevre.png",
        activeImg: "images/chevreValidated.png",
      },
      {
        name: AvailablesAvatar.TRICERATOPS,
        img: "images/trice.png",
        activeImg: "images/triceValidated.png",
      },
      {
        name: AvailablesAvatar.CHAT,
        img: "images/chat.png",
        activeImg: "images/chatValidated.png",
      },
    ],
    []
  );

  const onAvatarSelected = useCallback(
    (name: String) => {
      setSelectedAvatar(avatars.find((item) => item.name === name));
    },
    [avatars]
  );

  const validSelectedAvatar = useCallback(() => {
    props.onAvatarSelected(Avatar[selectedAvatar!.name]);
  }, [props, selectedAvatar]);

  return (
    <div className="SelectAvatarContainer">
      {avatars.map((item) => (
        <div
          key={item.name}
          className="AvatarContainer"
          onClick={() => {
            onAvatarSelected(item.name);
          }}
        >
          <img
            src={selectedAvatar?.name === item.name ? item.activeImg : item.img}
            className="PictureImage"
          />
        </div>
      ))}
      <div className="buttonbottomAvatar margindemort">
        <div className="buttonContainerAvatar">
          <button
            className="ButttonValidAvatar"
            onClick={validSelectedAvatar}
            disabled={selectedAvatar === undefined}
          >
            Valider
          </button>
        </div>
      </div>
    </div>
  );
}
