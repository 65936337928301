import { useCallback, useState } from "react";
import { VotingResult } from "../AppState";
import "./VotingComponent.css";

export interface VotingComponentProps {
  players: string[];
  onValidatedVoting: (votinResult: VotingResult) => void;
}

export interface VoteState {
  firstVote: string | undefined;
  secondVote: string | undefined;
  thirdVote: string | undefined;
}

export function VotingComponent(props: VotingComponentProps) {
  const [sentenceValue, setSentenceValue] = useState("");

  const [selectedVote, setSelectedVote] = useState<VoteState>({
    firstVote: undefined,
    secondVote: undefined,
    thirdVote: undefined,
  });

  const onValidated = useCallback(() => {
    props.onValidatedVoting({
      firstPlayerName: selectedVote.firstVote!!,
      secondPlayerName: selectedVote.secondVote!!,
      thirdPlayerName: selectedVote.thirdVote!!,
    });
  }, [props, selectedVote.firstVote, selectedVote.secondVote, selectedVote.thirdVote]);

  const onItemClicked = useCallback(
    (username: string, index: number) => {
      //need to remove a vote
      if (username === selectedVote.firstVote) {
        setSelectedVote({
          ...selectedVote,
          firstVote: undefined,
        });
        return;
      }
      if (username === selectedVote.secondVote) {
        setSelectedVote({
          ...selectedVote,
          secondVote: undefined,
        });
        return;
      }
      if (username === selectedVote.thirdVote) {
        setSelectedVote({
          ...selectedVote,
          thirdVote: undefined,
        });
        return;
      }

      if (!selectedVote.firstVote) {
        setSelectedVote({
          ...selectedVote,
          firstVote: username,
        });
        return;
      }

      if (!selectedVote.secondVote) {
        setSelectedVote({
          ...selectedVote,
          secondVote: username,
        });
        return;
      }

      if (!selectedVote.thirdVote) {
        setSelectedVote({
          ...selectedVote,
          thirdVote: username,
        });
        return;
      }
    },
    [selectedVote]
  );

  const calculateBackground = useCallback(
    (item: string) => {
      if (item === selectedVote.firstVote) {
        return "#ffda40";
      }
      if (item === selectedVote.secondVote) {
        return "#a4b4ba";
      }
      if (item === selectedVote.thirdVote) {
        return "#dbaa79";
      }
      return "#ffffff";
    },
    [selectedVote.firstVote, selectedVote.secondVote, selectedVote.thirdVote]
  );

  return (
    <div className="StepContainer">
      <div className="VotingContainer">
        {props.players.map((item, index) => {
          return (
            <div
              key={item}
              className="VotingButton"
              style={{ backgroundColor: calculateBackground(item) }}
              onClick={() => {
                onItemClicked(item, index);
              }}
            >
              <h1>{index + 1}</h1>
            </div>
          );
        })}
      </div>

      <div className="buttonbottom">
        <div className="buttonContainer">
          <button
            className="ButttonValid"
            onClick={onValidated}
            disabled={
              selectedVote.firstVote === undefined ||
              selectedVote.secondVote === undefined ||
              selectedVote.thirdVote === undefined
            }
          >
            Valider
          </button>
        </div>
      </div>
    </div>
  );
}
