import { io } from "socket.io-client";
import { Avatar, VotingResult } from "../AppState";
import { JoinRoomRequest } from "./model/JoinRoomRequest";
import { SelectAvatarRequest } from "./model/SelectAvatarRequest";
import { SendSoundRequest } from "./model/SendSoundRequest";
import { StartGameRequest } from "./model/StartGameRequest";
import { ValidateStepOneRequest } from "./model/ValidateStepOneRequest";
import { ValidateStepThreeRequest } from "./model/ValidateStepThreeRequest";
import { ValidateStepTwoRequest } from "./model/ValidateStepTwoRequest";
import { ValidateVotingRequest } from "./model/ValidateVotingRequest";

export const socket = io("https://ggj24backend.gounon.eu");

export function joinRoom(roomId: string, userName: string) {
  const joinRequest: JoinRoomRequest = {
    roomId: roomId,
    username: userName,
  };
  socket.emit("joinRoom", joinRequest);
}

export function createRoom() {
  socket.emit("createRoom", "");
}

export function finishPrez(roomId: string) {
  socket.emit("presentationFinished", {
    roomId: roomId,
  });
}

export function startGame(roomId: string) {
  const startGameRequest: StartGameRequest = {
    roomId: roomId,
  };
  socket.emit("startGame", startGameRequest);
}

export function selectAvatar(roomId: string, username: string, avatar: Avatar) {
  const selectAvatarRequest: SelectAvatarRequest = {
    avatar: avatar,
    roomId: roomId,
    username: username,
  };
  socket.emit("selectAvatar", selectAvatarRequest);
}

export function validateStep1(
  roomId: string,
  username: string,
  words: string[]
) {
  const validateStepOneRequest: ValidateStepOneRequest = {
    roomId: roomId,
    username: username,
    words: words,
  };
  socket.emit("stepOneValidated", validateStepOneRequest);
}

export function validateStep2(
  roomId: string,
  username: string,
  pictureData: string
) {
  const validateStepTwoRequest: ValidateStepTwoRequest = {
    roomId: roomId,
    username: username,
    pictureData: pictureData,
  };
  socket.emit("stepTwoValidated", validateStepTwoRequest);
}

export function validateStep3(
  roomId: string,
  username: string,
  sentence: string
) {
  const validateStepThreeRequest: ValidateStepThreeRequest = {
    roomId: roomId,
    username: username,
    sentence: sentence,
  };
  socket.emit("stepThreeValidated", validateStepThreeRequest);
}

export function validateVoting(
  roomId: string,
  username: string,
  votingResult: VotingResult
) {
  const validateVotingRequest: ValidateVotingRequest = {
    roomId: roomId,
    username: username,
    firstVote: votingResult.firstPlayerName,
    secondVote: votingResult.secondPlayerName,
    thirdVote: votingResult.thirdPlayerName,
  };
  socket.emit("votingValidated", validateVotingRequest);
}

export function sendSound(roomId: string, avatarName: string) {
  const sendSoundRequest: SendSoundRequest = {
    roomId: roomId,
    avatarName: avatarName,
  };
  socket.emit("sendSound", sendSoundRequest);
}
