import { createRef, useCallback, useEffect, useMemo, useState } from "react";
import { ColorResult, GithubPicker } from "react-color";
import { ReactSketchCanvas, ReactSketchCanvasRef } from "react-sketch-canvas";
import "./StepTwoComponent.css";

export interface StepTwoComponentProps {
  words: string[];
  onValidatedPicture: (pictureData: string) => void;
}

export function StepTwoComponent(props: StepTwoComponentProps) {
  const [isColorPickerShown, setIsColorPickerShown] = useState(false);
  const [currentColor, setCurrentColor] = useState("#000000");
  const [hasSomethingBeenDrawed, setHasSomethingBeenDrawed] = useState(false);

  const wordDisplayValue = useMemo(() => {
    let result = "";
    for (var word of props.words) {
      result += word + " / ";
    }
    return result.slice(0, result.length - 3);
  }, [props.words]);

  const canvasRef = createRef<ReactSketchCanvasRef>();

  const onValidated = useCallback(() => {
    canvasRef.current?.exportImage("png").then((image: string) => {
      props.onValidatedPicture(image);
    });
  }, [canvasRef, props]);

  const onRevert = useCallback(() => {
    canvasRef.current?.undo();
  }, [canvasRef]);

  const onColorPicker = useCallback(() => {
    setIsColorPickerShown(true);
  }, []);

  const onColorChanged = useCallback((color: ColorResult) => {
    setCurrentColor(color.hex);
    setIsColorPickerShown(false);
  }, []);

  const onStroke = useCallback(() => {
    setHasSomethingBeenDrawed(true)
  }, [])
  

  useEffect(() => {
    if (canvasRef.current !== null || canvasRef.current !== undefined) {
      const event = new Event("canvasRendered");
      document.dispatchEvent(event);
    }
  }, [canvasRef]);

  return (
    <div className="StepTwoContainer">
      <h2 className="WordDisplay">{wordDisplayValue}</h2>

      <div className="PictureContainer">
        <ReactSketchCanvas
          ref={canvasRef}
          strokeColor={currentColor}
          onStroke={onStroke}
          className="dessin"
          style={{ border: "0px solid" }}
        />
        <div className="outil">
          <div
            className="block colourpicker"
            onClick={onColorPicker}
            style={{ backgroundColor: currentColor }}
          >
            {isColorPickerShown && (
              <GithubPicker
                triangle="hide"
                colors={[
                  "#000000",
                  "#ffffff",
                  "#ff61e4",
                  "#955200",
                  "#ff0003",
                  "#ffd800",
                  "#00a000",
                  "#0700cd",
                ]}
                onChangeComplete={(color: ColorResult) => {
                  onColorChanged(color);
                }}
              />
            )}
          </div>
          <div className="block reset" onClick={onRevert}>
            ↩
          </div>
        </div>
      </div>

      <div className="buttonbottomStep2">
        <div className="buttonContainerStep2">
          <button className="ButttonValidStep2" onClick={onValidated} disabled={!hasSomethingBeenDrawed}>
            Valider
          </button>
        </div>
      </div>
    </div>
  );
}
