import { PlayerJoinedEvent } from "./api/model/PlayerJoinedEvent";
import { PresentationStartedEvent } from "./api/model/PresentationStartedEvent";
import { Step1DataEvent } from "./api/model/Step1DataEvent";
import { Step2DataEvent } from "./api/model/Step2DataEvent";
import { Step3DataEvent } from "./api/model/Step3DataEvent";
import { ValidateStepOneEvent } from "./api/model/ValidateStepOneEvent";
import { ValidateStepThreeEvent } from "./api/model/ValidateStepThreeEvent";
import { ValidateStepTwoEvent } from "./api/model/ValidateStepTwoEvent";

export interface AppState {
  currentStep: GameStep;
  currentRoom: string | undefined;
  currentPlayer: Player | undefined;
  stepOneData: StepOneData;
  stepTwoData: StepTwoData;
  stepThreeData: StepThreeData;
  votingData: VotingData;
}

export interface StepOneData {
  letters: string[],
  words: string[],
}

export interface StepTwoData {
  words: string[],
  picture: string | undefined
}

export interface StepThreeData {
  picture: string | undefined,
  sentence: string | undefined
}

export interface VotingData {
  players: string[],
  votingResult: VotingResult | undefined
}

export interface VotingResult {
  firstPlayerName: string,
  secondPlayerName: string,
  thirdPlayerName: string,
}

export interface Player {
  username: string;
  avatar: string;
  isVip: boolean;
}

export enum Avatar {
  UNDEFINED = "UNDEFINED",
  MOUTON = "MOUTON",
  SINGE = "SINGE",
  SOURIS = "SOURIS",
  COCHON = "COCHON",
  OISEAU = "OISEAU",
  CHEVRE = "CHEVRE",
  TRICERATOPS = "TRICERATOPS",
  CHAT = "CHAT",
}

export enum GameStep {
  NEED_TO_LOGIN,
  CHOSE_AVATAR,
  WAITING_OTHER_PLAYERS,
  STEP_1,
  STEP_2,
  STEP_3,
  VOTE,
  WAITING_STEP_2,
  WAITING_STEP_3,
  WAITING_VOTE
}

export function getInitialAppState(): AppState {
  return {
    currentStep: GameStep.NEED_TO_LOGIN,
    currentPlayer: undefined,
    currentRoom: undefined,
    stepOneData: {
      letters: [],
      words: []
    },
    stepTwoData: {
      words: [],
      picture: undefined
    },
    stepThreeData: {
      picture: undefined,
      sentence: undefined
    },
    votingData: {
      players: [],
      votingResult: undefined
    }
  };
}

export function playerLogged(
  input: PlayerJoinedEvent,
  currentAppState: AppState
): AppState {
  return {
    ...currentAppState,
    currentRoom: input.roomId,
    currentPlayer: {
      username: input.username,
      avatar: Avatar.UNDEFINED,
      isVip: input.isVip,
    },
    currentStep: GameStep.CHOSE_AVATAR,
  };
}

export function playerSelectedAvatar(
  input: PlayerJoinedEvent,
  currentAppState: AppState
): AppState {
  return {
    ...currentAppState,
    currentPlayer: {
      username: input.username,
      avatar: input.avatar,
      isVip: input.isVip,
    },
    currentStep: GameStep.WAITING_OTHER_PLAYERS,
  };
}

export function step1Started(
  data: Step1DataEvent,
  currentAppState: AppState,
): AppState {
  return {
    ...currentAppState,
    currentStep: GameStep.STEP_1,
    stepOneData: {
      letters: data.letters,
      words: []
    }
  }
}

export function step2Started(
  data: Step2DataEvent,
  currentAppState: AppState,
): AppState {
  return {
    ...currentAppState,
    currentStep: GameStep.STEP_2,
    stepOneData: {
      ...currentAppState.stepOneData,
      words: data.words
    },
    stepTwoData: {
      words: data.words,
      picture: undefined
    }
  }
}

export function step3Started(
  data: Step3DataEvent,
  currentAppState: AppState,
): AppState {
  return {
    ...currentAppState,
    currentStep: GameStep.STEP_3,
    stepThreeData: {
      sentence: undefined,
      picture: data.picture
    }
  }
}

export function votingStarted(
  data: PresentationStartedEvent,
  currentAppState: AppState
): AppState {
  return {
    ...currentAppState,
    currentStep: GameStep.VOTE,
    votingData: {
      players: data.players.map(item => item.username),
      votingResult: undefined
    }
  }
}

export function step1Finished(
  data: ValidateStepOneEvent,
  currentAppState: AppState,
): AppState {
  return {
    ...currentAppState,
    currentStep: GameStep.WAITING_STEP_2,
    stepOneData: {
      letters: currentAppState.stepOneData.letters,
      words: data.words
    }
  }
}

export function step2Finished(
  data: ValidateStepTwoEvent,
  currentAppState: AppState,
): AppState {
  return {
    ...currentAppState,
    currentStep: GameStep.WAITING_STEP_3,
    stepTwoData: {
      ...currentAppState.stepTwoData,
      picture: data.pictureData
    }
  }
}

export function step3Finished(
  data: ValidateStepThreeEvent,
  currentAppState: AppState,
): AppState {
  return {
    ...currentAppState,
    currentStep: GameStep.WAITING_VOTE,
    stepThreeData: {
      ...currentAppState.stepThreeData,
      sentence: data.sentence
    }
  }
}
