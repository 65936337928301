import { useCallback, useState } from "react";
import "./StepThreeComponent.css";

export interface StepThreeComponentProps {
  picture: string;
  onValidatedSentence: (sentence: string) => void;
}

export function StepThreeComponent(props: StepThreeComponentProps) {
  const [sentenceValue, setSentenceValue] = useState("");

  const onValidated = useCallback(() => {
    props.onValidatedSentence(sentenceValue);
  }, [props, sentenceValue]);

  return (
    <div className="StepContainer">

      <div className="PictureContainerStepThree">
        <img className="Picture" src={props.picture} />
      </div>

      <div className="LetterContainer">
        <input
          className="InputComponentThree"
          value={sentenceValue}
          placeholder="Commente"
          onChange={(event) => {
            setSentenceValue(event.target.value);
          }}
        />
      </div>

      <div className="buttonbottom">
        <div className="buttonContainer">
          <button className="ButttonValid" onClick={onValidated}>
            Valider
          </button>
        </div>
      </div>
    </div>
  );
}
