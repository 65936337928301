import { useCallback, useEffect, useMemo, useState } from "react";
import "./App.css";
import {
  AppState,
  Avatar,
  GameStep,
  VotingResult,
  getInitialAppState,
  playerLogged,
  playerSelectedAvatar,
  step1Finished,
  step1Started,
  step2Finished,
  step2Started,
  step3Finished,
  step3Started,
  votingStarted,
} from "./AppState";
import {
  createRoom,
  finishPrez,
  joinRoom,
  selectAvatar,
  sendSound,
  socket,
  startGame,
  validateStep1,
  validateStep2,
  validateStep3,
  validateVoting,
} from "./api";
import { CreateRoomEvent } from "./api/model/CreateRoomEvent";
import { PlayerJoinedEvent } from "./api/model/PlayerJoinedEvent";
import { PresentationStartedEvent } from "./api/model/PresentationStartedEvent";
import { Step1DataEvent } from "./api/model/Step1DataEvent";
import { Step2DataEvent } from "./api/model/Step2DataEvent";
import { Step3DataEvent } from "./api/model/Step3DataEvent";
import { ValidateStepOneEvent } from "./api/model/ValidateStepOneEvent";
import { ValidateStepThreeEvent } from "./api/model/ValidateStepThreeEvent";
import { ValidateStepTwoEvent } from "./api/model/ValidateStepTwoEvent";
import { JoinRoomComponent } from "./component/RegisterComponent";
import { SelectAvatar } from "./component/SelectAvatar";
import { StepOneComponent } from "./component/StepOneComponent";
import { StepThreeComponent } from "./component/StepThreeComponent";
import { StepTwoComponent } from "./component/StepTwoComponent";
import { VotingComponent } from "./component/VotinComponent";
import { WaitingScreen } from "./component/WaitingScreen";
import "./design.css";

function App() {
  const [appState, setAppState] = useState<AppState>(getInitialAppState());

  useEffect(() => {
    console.log("App state = ", appState);
  }, [appState]);

  useEffect(() => {
    socket.on("connect", () => {
      console.log("Connected");
    });

    socket.on("roomCreated", (data: CreateRoomEvent) => {
      console.log("Room created", data);
    });

    socket.on("playerConfirmed", (playerJoined: PlayerJoinedEvent) => {
      setAppState(playerLogged(playerJoined, appState));
    });

    socket.on("step1Data", (playerData: Step1DataEvent) => {
      setAppState(step1Started(playerData, appState));
    });

    socket.on("step2Data", (playerData: Step2DataEvent) => {
      setAppState(step2Started(playerData, appState));
    });

    socket.on("step3Data", (playerData: Step3DataEvent) => {
      setAppState(step3Started(playerData, appState));
    });

    socket.on("votingData", (playerData: PresentationStartedEvent) => {
      setAppState(votingStarted(playerData, appState));
    });

    socket.on("stepOneValidatedConfirmed", (data: ValidateStepOneEvent) => {
      setAppState(step1Finished(data, appState));
    });

    socket.on("stepTwoValidatedConfirmed", (data: ValidateStepTwoEvent) => {
      setAppState(step2Finished(data, appState));
    });

    socket.on("stepThreeValidatedConfirmed", (data: ValidateStepThreeEvent) => {
      setAppState(step3Finished(data, appState));
    });

    socket.on("votingValidatedConfirmed", () => {
      setAppState(getInitialAppState());
    });

    socket.on(
      "playerSelectedAvatarConfirmed",
      (playerJoined: PlayerJoinedEvent) => {
        setAppState(playerSelectedAvatar(playerJoined, appState));
      }
    );

    socket.on("error", (error: string) => {
      console.log("Error : " + error);
    });

    return () => {
      socket.offAny();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appState]);

  const onJoin = useCallback((roomId: string, playerName: string) => {
    joinRoom(roomId, playerName);
  }, []);

  const onCreateParty = useCallback(() => {
    createRoom();
  }, []);

  const onFinishPrez = useCallback(() => {
    finishPrez(appState.currentRoom!!);
  }, [appState.currentRoom]);

  const onAvatarSelected = useCallback(
    (avatar: Avatar) => {
      selectAvatar(
        appState.currentRoom!!,
        appState.currentPlayer?.username!!,
        avatar
      );
    },
    [appState.currentPlayer?.username, appState.currentRoom]
  );

  const onGameStart = useCallback(() => {
    startGame(appState.currentRoom!!);
  }, [appState]);

  const hasToShowWaitingScreen = useMemo(() => {
    return (
      appState.currentStep === GameStep.WAITING_OTHER_PLAYERS ||
      appState.currentStep === GameStep.WAITING_STEP_2 ||
      appState.currentStep === GameStep.WAITING_STEP_3 ||
      appState.currentStep === GameStep.WAITING_VOTE
    );
  }, [appState.currentStep]);

  const onValidatedWords = useCallback(
    (words: string[]) => {
      validateStep1(
        appState.currentRoom!!,
        appState.currentPlayer?.username!!,
        words
      );
    },
    [appState.currentPlayer, appState.currentRoom]
  );

  const onValidatePicture = useCallback(
    (pictureData: string) => {
      validateStep2(
        appState.currentRoom!!,
        appState.currentPlayer?.username!!,
        pictureData
      );
    },
    [appState.currentPlayer?.username, appState.currentRoom]
  );

  const onValidatedSentence = useCallback(
    (sentence: string) => {
      validateStep3(
        appState.currentRoom!!,
        appState.currentPlayer?.username!!,
        sentence
      );
    },
    [appState.currentPlayer?.username, appState.currentRoom]
  );

  const onValidatedVoting = useCallback(
    (votinResult: VotingResult) => {
      validateVoting(
        appState.currentRoom!!,
        appState.currentPlayer?.username!!,
        votinResult
      );
    },
    [appState.currentPlayer?.username, appState.currentRoom]
  );

  const onWaitingButtonPress = useCallback(
    (avatarName: string) => {
      sendSound(appState.currentRoom!!, avatarName);
    },
    [appState.currentRoom]
  );

  return (
    <body>
      <div className="App">
        <header className="header">
          <div className="logo"></div>
        </header>
        <div className="body">
          {appState.currentStep === GameStep.NEED_TO_LOGIN && (
            <JoinRoomComponent onJoin={onJoin} />
          )}
          {appState.currentStep === GameStep.CHOSE_AVATAR && (
            <SelectAvatar onAvatarSelected={onAvatarSelected} />
          )}
          {appState.currentStep === GameStep.STEP_1 && (
            <StepOneComponent
              userLetters={appState.stepOneData.letters}
              onValidatedWords={onValidatedWords}
            />
          )}
          {appState.currentStep === GameStep.STEP_2 && (
            <StepTwoComponent
              words={appState.stepTwoData.words}
              onValidatedPicture={onValidatePicture}
            />
          )}
          {appState.currentStep === GameStep.STEP_3 && (
            <StepThreeComponent
              picture={appState.stepThreeData.picture!!}
              onValidatedSentence={onValidatedSentence}
            />
          )}
          {appState.currentStep === GameStep.VOTE && (
            <VotingComponent
              players={appState.votingData.players}
              onValidatedVoting={onValidatedVoting}
            />
          )}
          {hasToShowWaitingScreen && (
            <WaitingScreen
              avatarName={appState.currentPlayer?.avatar!!}
              onWaitingButtonPress={onWaitingButtonPress}
              hasVipButton={
                appState.currentStep === GameStep.WAITING_OTHER_PLAYERS &&
                appState.currentPlayer?.isVip!!
              }
              onGameStart={onGameStart}
            />
          )}
        </div>
      </div>
      {appState.currentStep === GameStep.NEED_TO_LOGIN && (
        <footer>
          <div className="description">
            <a href="#">Global Game Jam 2024</a>
            <p>
              Created by Antoine Pitavy - Cyril Friederich - Nicolas Sagon -
              Jordan Chaneac - Bastien Gounon
            </p>
          </div>
        </footer>
      )}
    </body>
  );
}

export default App;
