import { useCallback, useState } from "react";
import "./StepOneComponent.css";

export interface StepOneComponentProps {
  userLetters: string[];
  onValidatedWords: (values: string[]) => void
}

export function StepOneComponent(props: StepOneComponentProps) {
  const [firstWord, setFirstWord] = useState("");
  const [secondWord, setSecondWord] = useState("");
  const [thirdWord, setThirdWord] = useState("");

  const getGoodState = useCallback(
    (index: number) => {
      if (index === 0) {
        return firstWord;
      }
      if (index === 1) {
        return secondWord;
      }

      if (index === 2) {
        return thirdWord;
      }
    },
    [firstWord, secondWord, thirdWord]
  );

  const updateStates = useCallback((value: string, index: number) => {
    if (index === 0) {
      setFirstWord(value);
    }
    if (index === 1) {
      setSecondWord(value);
    }

    if (index === 2) {
      setThirdWord(value);
    }
  }, []);

  const onValidated = useCallback(() => {
    props.onValidatedWords([
        firstWord,
        secondWord,
        thirdWord
    ])
  }, [firstWord, props, secondWord, thirdWord])

  return (
    <div className="StepContainer">
      {props.userLetters.map((item, index) => {
        return (
          <div key={item} className="LetterContainer">
            <h2>{item}</h2>
            <input className="Input" type="text" value={getGoodState(index)} onChange={(event) => updateStates(event.target.value, index)} />
          </div>
        );
      })}
      <div className="buttonbottom">
        <div className="buttonContainer">
          <button className="ButttonValid" onClick={onValidated} disabled={firstWord.length <= 1 || secondWord.length <= 1 || thirdWord.length <= 1}>
            Valider
          </button>
        </div>
      </div>
    </div>
  );
}
